import React, { Component } from 'react';
import "../styles/Home.css";
import "../styles/Responsive.css";
import {spray} from "../funzioni/spray";
import {circleText} from "../funzioni/circleText";
import Logo from "../images/logo fondo scuro.svg";
import Logo_dark from "../images/logo sfondo chiaro.svg";
import Cerchio from "../images/cerchio_button.svg";
import Cerchio_dark from "../images/POINTLESS BUTTON NERO.svg";
import $ from "jquery";
import iconaMail from "../images/envelope-solid.svg";
import behance from "../images/behance.svg";
import torna_su_standard from "../images/ICONA STANDARD.svg";
import torna_su_over from "../images/ICONA OVER.svg";

export default class home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      linguaSelezionata: 0,
      coloreSelezionato: 0,
      opened: 0
    }
    this.changeLang = this.changeLang.bind(this);
    this.changeColorBurron = this.changeColorBurron.bind(this);
    this.invertColors = this.invertColors.bind(this);
    this.openSideMenu = this.openSideMenu.bind(this);
    this.tornaSu = this.tornaSu.bind(this)
  }

  
  componentDidMount(){

    if(window.performance.navigation.type == 2){
      window.location.reload(true);
   }

    var isMobile = {
      Android: function() {
          return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function() {
          return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function() {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function() {
          return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function() {
          return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
      },
      any: function() {
          return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
      }
    };
    if(!isMobile.any()) spray();
    //circleText();
    //$(this).addClass("tornaColoreOriginale")};
    $(".torna_su").css("display", "none");
    window.addEventListener("scroll", ()=>{
      if(window.scrollY === 0 || this.state.opened === 1){
        //user is at the top of the page; no need to show the back to top button
        $(".torna_su").css("display", "none");
      } else {
        $(".torna_su").css("display", "block");
      }
    });
    
    let check = $("#checked");
    check.checked = false;

    if(!isMobile.any()){
      $("#su_standard").hover(() => {
        $("#su_standard").addClass("over_hide");
        $("#su_over").removeClass("over_hide");
      });

      $("#su_over").mouseout(() => {
        $("#su_over").addClass("over_hide");
        $("#su_standard").removeClass("over_hide");
      });
    }

  }

  changeLang(){
    let linguaSelezionata = this.state.linguaSelezionata === 0 ? 1 : 0;
    this.setState({
      linguaSelezionata
    })
  }

  changeColorBurron(){
    let arrColori = [
      "NAVY","BLUE","AQUA","TEAL","OLIVE", "GREEN", "GREEN2","LIME","YELLOW","ORANGE","RED","MAROON","FUCHSIA","PURPLE","BLACK","GRAY","SILVER"];
    let randomValue = arrColori[Math.floor(Math.random() * arrColori.length)];
    $("#pointless_button").removeClass();
    $("#pointless_button").addClass("button_" + randomValue);
  }

  invertColors(){
    let coloreSelezionato = this.state.coloreSelezionato === 0 ? 1 : 0;
    this.setState({
      coloreSelezionato
    }, ()=>{
      if(coloreSelezionato === 0){
        $(".contenitore_sito_invert").removeClass("contenitore_sito_invert");
        $(".cont_generale_invert").removeClass("cont_generale_invert");
        $(".applica_colore_invert").removeClass("applica_colore_invert");
        $(".slider_invert").removeClass("slider_invert");
        $(".inside_mobile_menu_revert").removeClass("inside_mobile_menu_revert");
        $(".menu_mobile_invert").removeClass("menu_mobile_invert");
        $(".menuToggle_revert").removeClass("menuToggle_revert");
        $(".header_invert").removeClass("header_invert");
        $(".icona_mail_invert").removeClass("icona_mail_invert");
        $(".icon_behance_invert").removeClass("icon_behance_invert");
        $(".phone_footer_invert").removeClass("phone_footer_invert");
      }else {
        $(".contenitore_sito").addClass("contenitore_sito_invert");
        $(".cont_generale").addClass("cont_generale_invert");
        $(".applica_colore").addClass("applica_colore_invert");
        $(".slider").addClass("slider_invert");
        $(".inside_mobile_menu").addClass("inside_mobile_menu_revert");
        $(".menu_mobile").addClass("menu_mobile_invert");
        $("#menuToggle").addClass("menuToggle_revert");
        $(".header").addClass("header_invert");
        $(".icona_mail").addClass("icona_mail_invert");
        $(".icon_behance").addClass("icon_behance_invert");
        $(".phone_footer").addClass("phone_footer_invert");
      }
    })
  }

  openSideMenu(){
    let opened = this.state.opened === 0 ? 1 : 0;
    this.setState({
      opened
    },()=>{ 
      if(opened === 0){
        $(".menu_mobile").removeClass("show_mobile_menu");
        $(".menu_mobile").addClass("hide_mobile_menu");

        if(!window.scrollY === 0){
          $(".torna_su").css("display", "block");
        }
      }else{
        var fixed = document.getElementById('menu_mobile');
        fixed.addEventListener('touchmove', function(e) {

                e.preventDefault();

        }, false);
        $(".menu_mobile").addClass("show_mobile_menu");
        $(".menu_mobile").removeClass("hide_mobile_menu");
        $(".torna_su").css("display", "none");
      }
    });
  }

  tornaSu(){
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    });
  }

  render() {
    const languages = [
      require('../languages/eng.json'),
      require('../languages/ita.json')
    ];
    const lingua_selezionata = languages[this.state.linguaSelezionata];

    let mail = <a href="mailto:info@2n1.it"><img className="icona_mail" src={iconaMail} /></a>;
    let mail_footer = <a href="mailto:info@2n1.it"><img className="icona_mail icon_mail_footer" src={iconaMail} /></a>;  
    let behance_icon = <a href="https://www.behance.net/2N1"><img className="icon_behance" src={behance} /></a>;
               
    return (
      <>
      <div className="mobile">
        <div id="menuToggle" onClick={this.openSideMenu}>
          <input type="checkbox" id="check"/>
          <span></span>
          <span></span>
          <span></span>
          <div></div>
        </div>
      </div>

      <div className="cont_generale" >
        <div id="cursor"></div>

        <div className="torna_su" onClick={this.tornaSu}>
          <div className="cont_arrow_top">
              <img src={torna_su_standard} id="su_standard" className="torna_su_icona"/>
              <img src={torna_su_over} id="su_over" className="torna_su_icona over_hide"/>
          </div>
        </div>

        <div className="contenitore_sito" id="cont_generale">

          <div className="header">
            <div className="header_item logo"><img src={this.state.coloreSelezionato === 0 ? Logo : Logo_dark} /></div>
            <div className="header_item"></div>
            
            <div className="header_item contatti_lingua desktop">
              <div className="cont_switcher">
                <div className="lingua_select">DARK &nbsp;</div> 
                  <div className="cont_button_switch">
                    <label className="switch">
                      <input type="checkbox" 
                      className="checkbox" 
                      onChange={this.invertColors}/>
                      <span className="slider round"></span>
                    </label> 
                  </div>  
                <div className="lingua_select"> &nbsp; LIGHT</div>
              </div>  
            </div> 

            <div className="header_item contatti_lingua responsive_lingua desktop">
              <div className="cont_switcher">
                <div className="lingua_select">ENG &nbsp;</div> 
                  <div className="cont_button_switch">
                    <label className="switch">
                      <input type="checkbox" 
                      className="checkbox" 
                      onChange={this.changeLang}/>
                      <span className="slider round"></span>
                    </label> 
                  </div>  
                <div className="lingua_select"> &nbsp; ITA</div>
                <div className="desktop cont_mail">{mail}</div>
              </div>  
            </div> 
          </div>

          <div className="contenitore_contenuto">
            <div className="landing">
              <div className="title_landing">{lingua_selezionata.motto}<span className="title_landing_punto">.</span></div><br />
            </div>
            <div className="cont_desc_landing">
              <span className="desc_landing"> {lingua_selezionata.based}</span><br />
              <span className="desc_landing"> {lingua_selezionata.work} <a href="https://www.behance.net/2N1"><span className="applica_colore">{lingua_selezionata.here_work}</span></a></span> <br />
              <span className="desc_landing"> {lingua_selezionata.write_us} <a href="mailto:info@2n1.it"><span className="applica_colore">{lingua_selezionata.write_here}</span></a></span>
            </div>
          </div>

          <div className="desc_scoll">
            {lingua_selezionata.or_scroll} <span className="applica_colore">{lingua_selezionata.scroll_down}</span><br />
            {lingua_selezionata.fun}
          </div>

          <div className="scritte_sito">
            <span className="scritta_body" id="scritta_1">{lingua_selezionata.line_1}</span> <br />
            <span className="scritta_body" id="scritta_2">{lingua_selezionata.line_2}</span>
            <span className="scritta_body" id="scritta_3">{lingua_selezionata.line_3}</span>
            <span className="scritta_body" id="scritta_4">{lingua_selezionata.line_4}</span>
            <span className="scritta_body" id="scritta_5">{lingua_selezionata.line_5}</span>
            <span className="scritta_body" id="scritta_6">{lingua_selezionata.line_6}<br />{lingua_selezionata.line_6_capo}</span>
            <span className="scritta_body" id="scritta_7">{lingua_selezionata.line_7}</span>
            <span className="scritta_body" id="scritta_8">{lingua_selezionata.line_8}</span>
            <span className="scritta_body" id="scritta_9">{lingua_selezionata.line_9}<br />
            {lingua_selezionata.line_9_capo_1}<br />
            {lingua_selezionata.line_9_capo_2} <span className="applica_colore">{lingua_selezionata.line_9_enjoy}</span></span>
          </div>

          <div className="cont_pointless_button">
            <div className="cont_cerchio_pointless_button"><img src={this.state.coloreSelezionato === 0 ? Cerchio : Cerchio_dark} className="cerchio_pointless_button"/></div>
            <div id="pointless_button" className="button_GREEN" onClick={() => this.changeColorBurron()}>
              <div className="inside_button">Press <br/> me</div>
            </div>
          </div>

          <div className="footer">
            <div className="desktop mail_footer">{mail_footer}</div>
            <div className="desktop behance_footer">
              {behance_icon}
            </div>
            <div className="scitta_footer">
              <div className="elem_scitta_footer">{lingua_selezionata.tip}</div>
            </div>
          </div>

        </div>
      </div>

      <div className="menu_mobile" id="menu_mobile">
        <div className="inside_mobile_menu">
          
        <div className="header_item contatti_lingua elem_mobile_menu switcher_mobile_menu">
              <div className="cont_switcher">
                <div className="lingua_select">DARK &nbsp;</div> 
                  <div className="cont_button_switch">
                    <label className="switch">
                      <input type="checkbox" 
                      className="checkbox" 
                      onChange={this.invertColors}/>
                      <span className="slider round"></span>
                    </label> 
                  </div>  
                <div className="lingua_select"> &nbsp; LIGHT</div>
              </div>  
            </div> 

            <div className="header_item contatti_lingua elem_mobile_menu switcher_mobile_menu">
              <div className="cont_switcher responsive_lingua">
                <div className="lingua_select">ENG &nbsp;</div> 
                  <div className="cont_button_switch">
                    <label className="switch">
                      <input type="checkbox" 
                      className="checkbox" 
                      onChange={this.changeLang}/>
                      <span className="slider round"></span>
                    </label> 
                  </div>  
                <div className="lingua_select"> &nbsp; ITA</div>
                <div className="desktop cont_mail">{mail}</div>
              </div>  
            </div> 
          <div className="elem_mobile_menu contatti_mobile">
            <div>{mail_footer}</div>
            <div>{behance_icon}</div>
            <div className="cont_phone_footer"><a className="phone_footer" href="tel: 3804315019">TEL: 3804315019</a></div>
          </div>
        </div>
      </div>

      </>
    )
  }
}
